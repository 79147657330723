.HomeProjects {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
}
.projects {
  width: 95%;
  background: var(--child-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  margin: 10px 0;
  padding: 50px 0;
  color: var(--child-font-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}

.porject-header {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.porject-header h2 {
  size: 18px;
  font-weight: 600;
}
.porject-header a {
  background: var(--bg-color);
  padding: 10px 50px;
  border-radius: 10px;
  text-decoration: none;
  border: 2px double transparent;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: var(--child-font-color);
  transition: 0.5s;
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}
.porject-header a:hover {
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
  transition: all 0.3s ease;
}
@media (max-width: 820px), (max-height: 900px) {
  .projects {
    padding: 30px 0;
  }
  .porject-header a {
    padding: 10px 30px;
  }
}

@media (max-width: 376px), (max-height: 668px) {
  .projects {
    padding: 20px 0;
  }
  .porject-header a {
    padding: 10px 20px;
  }
}
