.home {
  width: 60%;
  height: -webkit-fill-available;
  position: fixed;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--bg-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
  --primary-color: 111, 76, 255;
}

.home::-webkit-scrollbar {
  width: 0px;
}

.home.light {
  --bg-color: white;
  --child-bg-color: #f6f8fa;
  --child-font-color: black;
  --shadow-color-1: rgba(0, 0, 0, 0.19);
  --shadow-color-2: rgba(0, 0, 0, 0.23);
}
.home.dark {
  --bg-color: #212121;
  --child-bg-color: #2c2c2c;
  --child-font-color: white;
  --shadow-color-1: rgba(255, 255, 255, 0.19);
  --shadow-color-2: rgba(255, 255, 255, 0.23);
}
@media (max-width: 820px), (max-height: 900px) {
  .home {
    width: 95%;
    top: 15%;
  }
}

@media (max-height: 950px) {
  .home {
    width: 95%;
    top: 15%;
  }
}
@media (max-height: 650px) {
  .home {
    width: 95%;
    top: 18%;
  }
}
@media (max-height: 450px) {
  .home {
    width: 95%;
    top: 25%;
  }
}

@media (max-height: 280px) {
  .home {
    width: 95%;
    top: 35%;
  }
}
