.about {
  width: 60%;
  height: -webkit-fill-available;
  position: fixed;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--bg-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
  --primary-color: 111, 76, 255;
}

.about::-webkit-scrollbar {
  width: 0px;
}

.about.light {
  --bg-color: white;
  --child-bg-color: #f6f8fa;
  --child-font-color: black;
  --shadow-color-1: rgba(0, 0, 0, 0.19);
  --shadow-color-2: rgba(0, 0, 0, 0.23);
}
.about.dark {
  --bg-color: #212121;
  --child-bg-color: #2c2c2c;
  --child-font-color: white;
  --shadow-color-1: rgba(255, 255, 255, 0.19);
  --shadow-color-2: rgba(255, 255, 255, 0.23);
}
.preset-me {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--child-font-color);
  margin-bottom: 15px;
}
.preset-me h1 {
  text-align: flex-start;
  width: 80%;
  font-weight: 600;
  font-size: 30px;
  margin: 40px 0 20px 0;
}
.preset-me p {
  margin-left: 20px;
  text-align: flex-start;
  width: 90%;
  font-size: 18px;
  margin-bottom: 15px;
}
.more-about {
  width: 95%;
  background: var(--child-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 20px 0;
  color: var(--child-font-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}

.header-more {
  width: 90%;
  text-align: flex-start;
}
.header-more h3 {
  text-align: flex-start;
  size: 25px;
  font-weight: 600;
  margin-bottom: 15px;
}

.body-more {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.body-more p {
  text-align: flex-start;
  font-size: 18px;
  margin-bottom: 15px;
}

@media (max-width: 820px), (max-height: 900px) {
  .about {
    width: 95%;
    top: 15%;
    padding: 20px 0;
    margin: 10px 0;
  }
  .preset-me {
    align-items: center;
    width: 100%;
  }
}

@media (max-height: 950px) {
  .about {
    width: 95%;
    top: 15%;
    padding: 20px 0;
    margin: 10px 0;
  }
  .preset-me {
    align-items: center;
    width: 100%;
  }
}
@media (max-height: 650px) {
  .about {
    width: 95%;
    top: 18%;
    padding: 20px 0;
    margin: 10px 0;
  }
  .preset-me {
    align-items: center;
    width: 100%;
  }
}
@media (max-height: 450px) {
  .about {
    width: 95%;
    top: 25%;
    padding: 20px 0;
    margin: 10px 0;
  }
  .preset-me {
    align-items: center;
    width: 100%;
  }
}

@media (max-height: 280px) {
  .about {
    width: 95%;
    top: 35%;
    padding: 20px 0;
    margin: 10px 0;
  }
  .preset-me {
    align-items: center;
    width: 100%;
  }
}
