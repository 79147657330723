.project-details {
  width: 95%;
  background: var(--child-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  margin: 10px 0;
  padding: 50px 0;
  color: var(--child-font-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}
.back-div {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.project-details button,
.project-links a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  cursor: pointer;
  border: 2px double transparent;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;

  padding: 10px 30px;
  border-radius: 10px;
  width: 200px;
  text-decoration: none;
  color: var(--child-font-color);
  transition: all 0.5s ease;
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}
.project-details button,
.project-links a:hover {
  --primary-color: 111, 76, 255;
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

.details-body {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.details-body h1 {
  font-weight: 600;
  font-size: 30px;
  margin: 15px 0;
}
.details-body p {
  font-size: 20px;
  margin: 15px 0;
}

.project-links {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: var(--child-font-color);
  margin: 15px 15px;
}

.details-extra {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.project-view-big {
  width: 80%;
  margin: 15px 0;
  border-radius: 15px;
}
.details-extra p {
  font-size: 20px;
  margin: 15px 0;
}
@media (max-width: 820px), (max-height: 900px) {
  .back-div {
    justify-content: center;
  }
}
