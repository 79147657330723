.ProjectsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
}
.projects-List {
  width: 95%;
  background: var(--child-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  margin: 20px 0;
  padding: 50px 0;
  color: var(--child-font-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}

.title {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.title h2 {
  size: 18px;
  font-weight: 600;
}

@media (max-width: 820px), (max-height: 900px) {
  .projects-List {
    padding: 30px 0;
  }
}

@media (max-width: 376px), (max-height: 668px) {
  .projects-List {
    padding: 20px 0;
  }
}
