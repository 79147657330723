.project-card {
  width: 95%;
  height: 40%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: var(--background);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
  transition: 1s;
  margin: 10px 0;
}
.card {
  width: 95%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project-card:hover {
  box-shadow: var(--shadow-color-1) 0px 10px 20px,
    var(--shadow-color-2) 0px 6px 6px;
  background: var(--bg-hover);
}
.project-detail {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: transparent;
  margin: 10px 0;
}

.project-view {
  background: var(--child-font-color);
  margin: 10px 15px;
  width: 10%;
  height: 70%;
  border-radius: 5px;
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}
.description {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: var(--child-font-color);
  margin: 5px 15px;
}
.description h3 {
  font-size: 25px;
  margin-bottom: 5px;
}
.description p {
  font-size: 15px;
  margin: 5px;
}
.arrow {
  background: transparent;
  margin: 10px 15px;
  width: 10%;
  height: 70%;
}
.project-links {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  color: var(--child-font-color);
  margin: 15px 15px;
}
.project-links a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  cursor: pointer;
  border: 2px double transparent;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;

  padding: 10px 30px;
  border-radius: 10px;
  width: 200px;
  text-decoration: none;
  color: var(--child-font-color);
  transition: all 0.5s ease;
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}
.project-links a:hover {
  --primary-color: 111, 76, 255;
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

@media (max-width: 820px), (max-height: 900px) {
  .project-card {
    width: 95%;
    height: auto;
    flex-direction: column;
    justify-content: space-around;
    margin: 10px 0;
  }
  .card {
    flex-direction: column;
    height: fit-content;
  }
  .project-detail {
    width: 95%;
    height: 50%;
    flex-direction: column;
    margin: 10px 0;
  }

  .description {
    width: 80%;
    height: fit-content;
    align-items: center;
    color: var(--child-font-color);
    margin: 5px 15px;
  }
  .project-view {
    margin: 30px 15px;
    width: 50%;
  }
  .arrow {
    width: 15%;
    height: 25%;
  }
  .description h3 {
    text-align: center;
    font-size: 20px;
  }
  .description p {
    text-align: center;
    font-size: 12px;
  }
  .project-links {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .project-links a {
    margin: 10px 0;
  }
}
