.hire {
  width: 95%;
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  margin: 10px 0;
  color: var(--child-font-color);
}

.hire h1 {
  width: 60%;
  text-align: start;
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 10px;
}
.hire button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: var(--bg-color);
  cursor: pointer;
  border: 2px double transparent;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    radial-gradient(circle at left top, rgb(1, 110, 218), rgb(217, 0, 192));
  background-origin: border-box;
  background-clip: padding-box, border-box;

  padding: 10px 30px;
  border-radius: 10px;
  width: 200px;
  text-decoration: none;
  color: var(--child-font-color);
  transition: all 0.5s ease;
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}

.hire button img {
  width: 25px;
  height: 25px;
}
.hire button:hover {
  --primary-color: 111, 76, 255;
  box-shadow: rgba(var(--primary-color), 0.5) 0px 0px 20px 0px;
}

@media (max-width: 820px), (max-height: 900px) {
  .hire h1 {
    width: 60%;
    font-size: 20px;
    padding-bottom: 10px;
  }
  .hire button {
    padding: 10px 15px;
    width: 100px;
  }
}
