.footer {
  width: 95%;
  background: var(--child-bg-color);
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 15px;
  margin: 10px 0;
  color: var(--child-font-color);
  box-shadow: var(--shadow-color-1) 0px 3px 8px;
}

.footer p {
  font-size: 18px;
}
.footer p span {
  font-weight: 600;
}
