.presentation {
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  padding: 50px 0;
  margin: 10px 0;
  color: var(--child-font-color);
}

.presentation.light {
  --bg-color: white;
  --child-font-color: black;
  --shadow-color-1: rgba(0, 0, 0, 0.19);
}
.presentation.dark {
  --bg-color: #212121;
  --child-font-color: white;
  --shadow-color-1: rgba(255, 255, 255, 0.19);
}
.presentation .img {
  display: flex;
  width: 140px;
  height: 170px;
  align-items: center;
  justify-content: center;
}
.img img {
  width: 80%;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  object-position: 50% 4%;
  border: 5px double transparent;
  background-image: linear-gradient(var(--bg-color), var(--bg-color)),
    linear-gradient(0deg, #a427ce, #903bff, #2469ff);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: var(--shadow-color-1) 0px 6px 40px;
}
.pres-about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
}
.pres-about h1 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 10px;
}
.pres-about p {
  font-size: 18px;
}

@media (max-width: 820px), (max-height: 900px) {
  .presentation {
    width: 95%;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 20px 0;
    margin: 10px 0;
  }
  .pres-about {
    align-items: center;
    width: 90%;
  }
  .pres-about h1 {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 10px;
    text-align: center;
  }
  .pres-about p {
    font-size: 14px;
    text-align: center;
  }
}
